<template>
  <base-layout :title="title" :backLink="backLink" :reloadable="reloadable">
    <ion-grid fixed>
      <ion-row>
        <ion-col size="12">
          <ion-list>
            <ion-item class="ion-margin-bottom" lines="none">
              <ion-label position="floating">Due upto date</ion-label>
              <ion-datetime
                type="text"
                v-model="due_upto"
                placeholder="Select Date"
              ></ion-datetime>
              <ion-button fill="solid" slot="end" @click="confirmSMS">
                Send SMS
              </ion-button>
            </ion-item>
            <ion-item class="ion-margin-bottom" lines="none">
              <ion-label position="floating">Pay before date</ion-label>
              <ion-datetime
                type="text"
                v-model="pay_before"
                placeholder="Select Date"
              ></ion-datetime>
            </ion-item>
            <ion-item>
              <ion-label class="ion-text-wrap">
                <h2>Preview</h2>
                <p>
                  "Your monthly school fee due upto {{ due_upto_f }} is INR
                  {#var#}. Kindly visit the school and pay dues before
                  {{ pay_before_f }}"
                </p>
              </ion-label>
            </ion-item>
          </ion-list>
        </ion-col>
        <ion-col size="12" v-if="classrooms">
          <ion-item lines="none">
            <h5>
              Select Recipients (Selected: {{ selected_recipients.length }})
            </h5>
          </ion-item>
          <ion-item lines="none">
            <ion-label>Select everyone</ion-label>
            <ion-checkbox
              slot="end"
              style="margin-bottom: 0; margin-top: 0"
              @ionChange="toggleAll($event)"
            ></ion-checkbox>
          </ion-item>

          <ion-card v-for="item of classrooms" :key="item.id">
            <ion-card-header>
              <ion-card-title
                >Class {{ item.standard.name }} - {{ item.section.name }}
                <span v-if="user_ids[item.id]"
                  >({{
                    user_ids[item.id].length + "/" + item.students.length
                  }})</span
                >
              </ion-card-title>
              <ion-item lines="none">
                <ion-label>Select entire class</ion-label>
                <ion-checkbox
                  class="select-all-in-class"
                  :value="item.id"
                  slot="end"
                  style="margin-bottom: 0; margin-top: 0"
                  @ionChange="toggleSelectAll(item, $event)"
                ></ion-checkbox>
              </ion-item>
            </ion-card-header>
            <ion-card-content>
              <ion-item>
                <ion-label position="floating">Students</ion-label>
                <ion-select
                  multiple
                  :interface-options="{ cssClass: 'full-screen' }"
                  v-model="user_ids[item.id]"
                  :value="getValues(item.students)"
                  :disabled="disable_student_selection"
                >
                  <ion-select-option
                    v-for="student of item.students"
                    :key="student.id"
                    :value="student.user_id"
                  >
                    {{ `${student.user.user_detail.name}` }}
                  </ion-select-option>
                </ion-select>
              </ion-item>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </base-layout>
</template>

<script>
import BaseLayout from "../../../Base/BaseLayout.vue";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonLabel,
  IonDatetime,
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCheckbox,
  IonCardContent,
  IonSelect,
  IonSelectOption,
  loadingController,
  alertController,
  toastController,
} from "@ionic/vue";

import moment from "moment";

export default {
  components: {
    BaseLayout,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonItem,
    IonLabel,
    IonDatetime,
    IonButton,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCheckbox,
    IonCardContent,
    IonSelect,
    IonSelectOption,
  },
  data() {
    return {
      title: "Send Due SMS",
      backLink: "sms.template",
      reloadable: false,

      due_upto: new Date().toISOString(),
      pay_before: new Date().toISOString(),

      user_ids: {},

      disable_student_selection: true,

      xhrError: {
        header: null,
        description: null,
      },
    };
  },

  computed: {
    due_upto_f() {
      return moment(this.due_upto).format("dddd, MMMM Do YYYY");
    },

    pay_before_f() {
      return moment(this.pay_before).format("dddd, MMMM Do YYYY");
    },

    classrooms() {
      return this.$store.getters["unpaidDueSMS/allResources"];
    },

    selected_recipients() {
      let user_ids = [];
      for (let key in this.user_ids) {
        this.user_ids[key].forEach((element) => {
          user_ids.push(element);
        });
      }
      return user_ids;
    },
  },

  created() {
    this.fetchStudents();
  },

  methods: {
    getValues(students) {
      let values = [];
      students.forEach((student) => {
        if (student.user.fee_invoices.length > 0) {
          student.user.fee_invoices.forEach((invoice) => {
            if (invoice.payment == null) {
             values.push(student.user_id); 
            } else if (
              invoice.payment.status == "created" ||
              invoice.payment.status == "failed"
            ) {
              values.push(student.user_id);
            }
          });
        }
      });
      return values;
    },

    async toggleSelectAll(item, event) {
      let values = [];

      if (event.detail.checked) {
        for (let key in item.students) {
          values.push(item.students[key].user_id);
        }
      }

      this.user_ids[item.id] = values;
    },

    async toggleAll(event) {
      const toggles = document.querySelectorAll(".select-all-in-class");
      if (event.detail.checked) {
        for (let i = 0; i < toggles.length; i++) {
          toggles[i].checked = true;
        }
      } else {
        for (let i = 0; i < toggles.length; i++) {
          toggles[i].checked = false;
        }
      }
    },

    loader(message) {
      const loading = loadingController.create({
        message: message,
        backdropDismiss: false,
      });

      return loading;
    },

    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    toaster(message) {
      const toast = toastController.create({
        message,
        duration: 3000,
        color: "primary",
      });

      return toast;
    },

    async confirmSMS() {
      const count = this.selected_recipients.length;
      const header = `Confirm`;
      const message = `Are you sure you want to send ${count} SMS(s)?`;
      const buttons = [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Confirm",
          handler: () => {
            this.sendSMS();
          },
        },
      ];
      const alertDialog = await this.alerter(header, message, buttons);

      alertDialog.present();
    },

    async sendSMS() {
      let data = {
        due_upto: this.due_upto,
        pay_before: this.pay_before,
        user_ids: this.selected_recipients,
      };
      const spinner = await this.loader("Sending...");
      spinner.present();

      try {
        await this.$store.dispatch("unpaidDueSMS/sendSMS", data);
        spinner.dismiss();
        const toast = await this.toaster("SMS request queued");
        await toast.present();
        this.$router.replace({ name: "communication" });
      } catch (error) {
        spinner.dismiss();
        this.setErrorResponse(error);
        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        alertDialog.present();
      }
    },

    async selectStudentsWithUnpaidDue() {
      this.classrooms.forEach((classroom) => {
        this.user_ids[classroom.id] = [];
        classroom.students.forEach((student) => {
          student.user.fee_invoices.forEach((invoice) => {
            if (invoice.payment == null) {
              this.user_ids[classroom.id].push(student.user_id);
            } else if (
              invoice.payment.status == "created" ||
              invoice.payment.status == "failed"
            ) {
              this.user_ids[classroom.id].push(student.user_id);
            }
          });
        });
      });
    },

    async fetchStudents() {
      this.disable_student_selection = true;
      await this.$store.dispatch("unpaidDueSMS/allResources");
      await this.selectStudentsWithUnpaidDue();
      this.disable_student_selection = false;
    },
  },
};
</script>

<style scoped></style>
